var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isFetching)?_c('v-card',{staticClass:"card card-custom card-stretch gutter-b",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"font-weight-bolder text-dark table__header"},[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"card-toolbar"},[_c('ul',{staticClass:"nav nav-pills nav-pills-sm nav-dark-75"},[_c('li',{staticClass:"nav-item",on:{"click":() => {
              _vm.applyFilter('delivery');
            }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{
              'color__background--primary': _vm.filter === 'delivery',
            }},[_vm._v("Reparto")])]),_c('li',{staticClass:"nav-item",on:{"click":() => {
              _vm.applyFilter('takeaway');
            }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{
              'color__background--primary': _vm.filter === 'takeaway',
            }},[_vm._v("Recoger")])]),_c('li',{staticClass:"nav-item",on:{"click":() => {
              _vm.applyFilter('');
            }}},[_c('a',{staticClass:"nav-link py-1 px-4 table__buttons",class:{ 'color__background--primary': _vm.filter === '' }},[_vm._v("Todos")])])])])]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.orders.length)+" ")]),(_vm.orders.length !== 0)?_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('v-simple-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless borderless",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[(_vm.finalizados)?_c('tr',{staticClass:"text-left"},_vm._l((_vm.headers_finalizados),function(item){return _c('th',{key:item._id,staticClass:"p-0 borderless color__error"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_c('tr',{staticClass:"text-left"},_vm._l((_vm.headers),function(item){return _c('th',{key:item._id,staticClass:"p-0 borderless color__error"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('tbody',_vm._l((_vm.orders),function(item){return _c('tr',{key:item._id,class:{ 'back-grey': _vm.isToday(item.createdAt) === false },on:{"click":() => {
                _vm.editItem(item);
              }}},[(item.delivery === _vm.filter || _vm.filter === '')?[_c('td',{staticClass:"pb-4"},[(
                    item.delivery === 'delivery' &&
                    item.delivered_by !== 'local'
                  )?_c('v-icon',{class:{
                    'order--pagado': item.status === 'Pagado',
                    'order--preparacion': item.status === 'En preparación',
                    'order--reparto': item.status === 'En reparto',
                    'order--entregado': item.status === 'Entregado',
                  },attrs:{"large":""}},[_vm._v(" mdi-moped ")]):(
                    item.delivery === 'delivery' &&
                    item.delivered_by === 'local'
                  )?_c('v-icon',{class:{
                    'order--pagado': item.status === 'Pagado',
                    'order--preparacion': item.status === 'En preparación',
                    'order--reparto': item.status === 'En reparto',
                    'order--entregado': item.status === 'Entregado',
                  },attrs:{"large":""}},[_vm._v(" mdi-scooter ")]):(item.delivery !== 'delivery')?_c('v-icon',{class:{
                    'order--pagado': item.status === 'Pagado',
                    'order--preparacion': item.status === 'En preparación',
                    'order--reparto': item.status === 'En reparto',
                    'order--entregado': item.status === 'Entregado',
                  },attrs:{"large":""}},[_vm._v(" mdi-basket ")]):_vm._e()],1),_c('td',{staticClass:"pl-0"},[(!_vm.isToday(item.createdAt))?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatTime")(item.createdAt))+" ")]),(item.delivery === 'delivery')?_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"color__regular mb-1 font-14"},[_vm._v(" "+_vm._s(item.customerData.address)+" "+_vm._s(item.customerData.zipCode)+" "+_vm._s(item.customerData.city)+" ")])]):_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"color__regular mb-1 font-14"},[_vm._v(" "+_vm._s(item.customerData.firstName)+" "+_vm._s(item.customerData.lastName)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold d-block font-14"},[_vm._v(" "+_vm._s(item.customerData.phone)+" ")])]),(!_vm.finalizados && !item.deliveredBy)?_c('td',{staticClass:"pl-0 py-4"},[(_vm.isLocalAvailable(item.customerData.zipCode))?_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" Entrega local ")]):_vm._e(),(item.stuartPrice && !item.deliveredBy)?_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" Stuart: "+_vm._s(item.stuartPrice)+" € ")]):_vm._e(),(
                    item.delivery !== 'takeaway' &&
                    !item.stuartPrice &&
                    item.delivered_by !== 'stuart'
                  )?_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" Stuart no disponible ")]):_vm._e(),(
                    item.delivery !== 'takeaway' &&
                    !item.stuartPrice &&
                    item.delivered_by
                  )?_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.delivered_by))+": "+_vm._s(_vm._f("formatPrice")(item.deliveryCost))+" € ")]):_vm._e(),(item.delivery !== 'delivery')?_c('span',{staticClass:"color__regular font-weight-bolder mb-1 font-14"},[_vm._v(" Hora recogida: "+_vm._s(_vm._f("formatTime")(item.takeawayTime))+" ")]):_vm._e()]):_c('td',{staticClass:"pl-0 py-4"},[(item.delivery === 'delivery')?_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" "+_vm._s(item.delivered_by || 'Domicilio')+" ")]):_c('span',{staticClass:"font-weight-bolder d-block font-14"},[_vm._v(" Recoger en local ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"color__regular mb-1 font-14"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.orderPrice))+" € ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('span',{staticClass:"font-weight-bolder mb-1 font-size-lg",class:{
                    'order--pagado': item.status === 'Pagado',
                    'order--preparacion': item.status === 'En preparación',
                    'order--reparto': item.status === 'En reparto',
                    'order--entregado': item.status === 'Entregado',
                  }},[_vm._v(" "+_vm._s(item.status)+" ")])]),_c('td',{staticClass:"pl-0 py-4"},[_c('v-icon',{staticClass:"color__text--primary",attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)]:_vm._e()],2)}),0)]},proxy:true}],null,false,834317503)})],1):_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":"/media/misc/ZeroData.png","width":"500px","height":"200px"}})],1)],1):_c('v-card',{staticClass:"text-center",attrs:{"flat":"","min-height":"400px"}},[_c('h1',{staticClass:"mt-4"},[_vm._v("Cargando pedidos ...")]),_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":"/media/loader/loading.gif","width":"300px","height":"300px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }