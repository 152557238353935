<template>
  <v-card
    v-if="!isFetching"
    flat
    class="card card-custom card-stretch gutter-b"
  >
    <v-card-title class="font-weight-bolder text-dark table__header">
      <div>{{ title }}</div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li
            class="nav-item"
            @click="
              () => {
                applyFilter('delivery');
              }
            "
          >
            <a
              class="nav-link py-1 px-4 table__buttons"
              v-bind:class="{
                'color__background--primary': filter === 'delivery',
              }"
              >Reparto</a
            >
          </li>
          <li
            class="nav-item"
            @click="
              () => {
                applyFilter('takeaway');
              }
            "
          >
            <a
              class="nav-link py-1 px-4 table__buttons"
              v-bind:class="{
                'color__background--primary': filter === 'takeaway',
              }"
              >Recoger</a
            >
          </li>
          <li
            class="nav-item"
            @click="
              () => {
                applyFilter('');
              }
            "
          >
            <a
              class="nav-link py-1 px-4 table__buttons"
              v-bind:class="{ 'color__background--primary': filter === '' }"
              >Todos</a
            >
          </li>
        </ul>
      </div>
    </v-card-title>
    <v-card-subtitle>{{ orders.length }} </v-card-subtitle>
    <div class="card-body pt-0 pb-3" v-if="orders.length !== 0">
      <v-simple-table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless borderless"
      >
        <template v-slot:default>
          <thead>
            <tr v-if="finalizados" class="text-left">
              <th
                v-for="item in headers_finalizados"
                :key="item._id"
                class="p-0 borderless color__error"
              >
                {{ item }}
              </th>
            </tr>

            <tr v-else class="text-left">
              <th
                v-for="item in headers"
                :key="item._id"
                class="p-0 borderless color__error"
              >
                {{ item }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in orders"
              :key="item._id"
              @click="
                () => {
                  editItem(item);
                }
              "
              v-bind:class="{ 'back-grey': isToday(item.createdAt) === false }"
            >
              <template v-if="item.delivery === filter || filter === ''">
                <td class="pb-4">
                  <v-icon
                    v-if="
                      item.delivery === 'delivery' &&
                      item.delivered_by !== 'local'
                    "
                    large
                    v-bind:class="{
                      'order--pagado': item.status === 'Pagado',
                      'order--preparacion': item.status === 'En preparación',
                      'order--reparto': item.status === 'En reparto',
                      'order--entregado': item.status === 'Entregado',
                    }"
                  >
                    mdi-moped
                  </v-icon>
                  <v-icon
                    v-else-if="
                      item.delivery === 'delivery' &&
                      item.delivered_by === 'local'
                    "
                    large
                    v-bind:class="{
                      'order--pagado': item.status === 'Pagado',
                      'order--preparacion': item.status === 'En preparación',
                      'order--reparto': item.status === 'En reparto',
                      'order--entregado': item.status === 'Entregado',
                    }"
                  >
                    mdi-scooter
                  </v-icon>
                  <v-icon
                    v-else-if="item.delivery !== 'delivery'"
                    large
                    v-bind:class="{
                      'order--pagado': item.status === 'Pagado',
                      'order--preparacion': item.status === 'En preparación',
                      'order--reparto': item.status === 'En reparto',
                      'order--entregado': item.status === 'Entregado',
                    }"
                  >
                    mdi-basket
                  </v-icon>
                </td>
                <td class="pl-0">
                  <div v-if="!isToday(item.createdAt)">
                    {{ item.createdAt | formatDate }}
                  </div>
                  {{ item.createdAt | formatTime }}
                </td>

                <!-- DIRECCIÓN O DATOS DE RECOGIDA -->
                <td v-if="item.delivery === 'delivery'" class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.customerData.address }}
                    {{ item.customerData.zipCode }} {{ item.customerData.city }}
                  </span>
                </td>
                <td v-else class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.customerData.firstName }}
                    {{ item.customerData.lastName }}
                  </span>
                  <span class="text-muted font-weight-bold d-block font-14">
                    {{ item.customerData.phone }}
                  </span>
                </td>

                <td v-if="!finalizados && !item.deliveredBy" class="pl-0 py-4">
                  <span
                    v-if="isLocalAvailable(item.customerData.zipCode)"
                    class="font-weight-bolder d-block font-14"
                  >
                    Entrega local
                  </span>
                  <span
                    v-if="item.stuartPrice && !item.deliveredBy"
                    class="font-weight-bolder d-block font-14"
                  >
                    Stuart: {{ item.stuartPrice }} €
                  </span>
                  <!-- <span
                    v-if="item.delivery !== 'takeaway' && item.vromoPrice"
                    class="font-weight-bolder d-block font-14"
                  >
                    Vromo: {{ item.vromoPrice }} €
                  </span> -->
                  <span
                    v-if="
                      item.delivery !== 'takeaway' &&
                      !item.stuartPrice &&
                      item.delivered_by !== 'stuart'
                    "
                    class="font-weight-bolder d-block font-14"
                  >
                    Stuart no disponible
                  </span>
                  <span
                    v-if="
                      item.delivery !== 'takeaway' &&
                      !item.stuartPrice &&
                      item.delivered_by
                    "
                    class="font-weight-bolder d-block font-14"
                  >
                    {{ item.delivered_by | capitalize }}:
                    {{ item.deliveryCost | formatPrice }} €
                  </span>

                  <span
                    v-if="item.delivery !== 'delivery'"
                    class="color__regular font-weight-bolder mb-1 font-14"
                  >
                    Hora recogida: {{ item.takeawayTime | formatTime }}
                  </span>
                </td>
                <td v-else class="pl-0 py-4">
                  <span
                    v-if="item.delivery === 'delivery'"
                    class="font-weight-bolder d-block font-14"
                  >
                    {{ item.delivered_by || 'Domicilio' }}
                  </span>
                  <!-- recogida -->
                  <span v-else class="font-weight-bolder d-block font-14">
                    Recoger en local
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <span class="color__regular mb-1 font-14">
                    {{ item.orderPrice | formatPrice }} €
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <span
                    class="font-weight-bolder mb-1 font-size-lg"
                    v-bind:class="{
                      'order--pagado': item.status === 'Pagado',
                      'order--preparacion': item.status === 'En preparación',
                      'order--reparto': item.status === 'En reparto',
                      'order--entregado': item.status === 'Entregado',
                    }"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td class="pl-0 py-4">
                  <v-icon large class="color__text--primary">
                    mdi-chevron-right
                  </v-icon>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="card-body pt-0 pb-3" v-else>
      <v-img
        src="/media/misc/ZeroData.png"
        width="500px"
        height="200px"
        style="margin: 0 auto"
      />
    </div>
  </v-card>
  <v-card v-else flat min-height="400px" class="text-center">
    <h1 class="mt-4">Cargando pedidos ...</h1>
    <v-img
      src="/media/loader/loading.gif"
      width="300px"
      height="300px"
      style="margin: 0 auto"
    />
  </v-card>
</template>

<script>
import moment from 'moment';
// import AppSpinner from "@/view/content/AppSpinner.vue";
import { mapGetters, mapState } from 'vuex';
export default {
  // src="https://marketplace.canva.com/MAB60VR-Abk/1/thumbnail_large/canva-man-delivering-boxes-on-scooter-icon-MAB60VR-Abk.png"
  // src="https://img.icons8.com/cotton/1x/take-away-food.png"
  components: {
    // AppSpinner
  },
  props: {
    orders: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    finalizados: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      headers_finalizados: [
        '',
        'Hora',
        'Dirección',
        'Tipo de envío',
        'Importe',
        'Estado',
        '',
      ],
      headers: [
        '',
        'Hora',
        'Dirección',
        'Tipo de envío',
        'Importe',
        'Estado',
        ' ',
      ],
      filter: '',
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['getZipCodes']),
    ...mapState({
      isFetching: (state) => state.orders.isFetching,
    }),
    isCloseTakeAway(str) {
      if (str) {
        let now = new Date();
        let recogida = new Date(str);
        if (
          (recogida.getTime() - now.getTime()) / 60000 < 20 &&
          (recogida.getTime() - now.getTime()) / 60000 > 0
        ) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    isToday(value) {
      let formatType = 'LL';
      if (!value) return '';
      let dateFormated = moment(value);
      let today = moment();
      dateFormated.locale('es');
      today.locale('es');
      return dateFormated.format(formatType) === today.format(formatType);
    },
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },

    isLocalAvailable(currentCode) {
      let codigos = this.getZipCodes;
      let codes = codigos.filter((code) => {
        return code.code === currentCode && code.local;
      });
      return codes.length > 0;
    },
    editItem(item) {
      // console.log(item)
      this.$emit('orderClicked', item);
    },
    applyFilter(value) {
      this.filter = value;
    },
    // isCloseTakeAway(str){
    //     let now=new Date()
    //     let recogida = new Date(str)
    //     if((((recogida.getTime()- now.getTime())/60000) < 20) && (((recogida.getTime()- now.getTime())/60000) > 0) ){
    //         return true
    //     }
    //     return false
    // }
  },
};
</script>

<style lang="scss">
.table__header {
  font-size: 16px;
  justify-content: space-between;
}

.table__buttons {
  font-size: 13px;
  font-weight: 400;
}

.borderless {
  border-bottom: none !important;
}

.h-50 {
  height: 50% !important;
}

.back-grey {
  background-color: beige;
}
</style>
