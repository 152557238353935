<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="400px"
    :overlay-opacity="0.9"
    overlay-color="#333333"
    ref="contenedor"
  >
    <v-card class="px-6 py-6">
      <div class="closeIcon">
        <v-btn icon @click="clickHandler" color="#000000">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-3"
            >¡NUEVO PEDIDO!</v-list-item-title
          >
          <v-list-item-subtitle>Revisa y dales de comer!</v-list-item-subtitle>
        </v-list-item-content>    
      </v-list-item>
      <v-card-actions>
        <v-col>
          <v-btn block color="rgb(255, 221, 23)" @click="clickHandler"
            >¡OIDO!</v-btn
          >
        </v-col>      
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  PROCESS_LOCAL_ORDER,
  PROCESS_RECOGIDA,
  PROCESS_STUART,
} from '@/core/services/store/orders.module';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    order: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: {
        show: false,
        text: '',
      },
      element: {
        size: '',
        variants: [],
        extras: [],
        comments: '',
      },
      audio: new Audio('https://papamono.es/campana.mp3'), // path to file
    };
  },
  watch: {
    isOpen: function (newVal, oldVal) {
      // watch it
      if (newVal) this.playSound();
    },
  },
  methods: {
    clickHandler() {
      this.audio.pause();
      this.$emit('closeModal', true);
    },
    //TODO hacer una función genérica como dios manda
    playSound() {
      this.audio.loop = true;
      this.audio.play();
    },
  },
};
</script>
<style>
.closeIcon {
  color: #000000;
  position: absolute;
  top: 8px;
  right: 8px;
  /* justify-content: end; */
  /* z-index: inherit; */
  text-align: end;
  /* margin-right: -75px;*/
}

.headline {
  font-size: 18px;
  font-weight: 600;
}
</style>
