import { render, staticRenderFns } from "./TicketListCardDeal.vue?vue&type=template&id=74048294&scoped=true&"
import script from "./TicketListCardDeal.vue?vue&type=script&lang=js&"
export * from "./TicketListCardDeal.vue?vue&type=script&lang=js&"
import style0 from "./TicketListCardDeal.vue?vue&type=style&index=0&id=74048294&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74048294",
  null
  
)

export default component.exports