<template>
  <div data-app v-if="order">
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="800px"
      :overlay-opacity="0.9"
      overlay-color="#333333"
      ref="contenedor"
    >
      <v-card class="p-6 regular-background">
        <div class="closeIcon">
          <v-btn icon @click="clickHandler" color="#000000">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="mx-2 white-background">
          <v-col class="mx-2">
            <TicketList v-if="order" :order="order" />
          </v-col>
          <v-col class="mx-2">
            <TicketAddress
              v-if="order"
              :order="order"
              :customerData="order.customerData"
              :delivery="order.delivery"
              :takeawayTime="order.takeawayTime"
              :stuartData="order.stuartRes"
              :stuartPrice="order.stuartPrice"
            />
            <!-- BOTONERA PARA RECOGER-->
            <div v-if="order.status !== 'Entregado'">
              <div v-if="order.delivery === 'takeaway'" class="mt-4">
                <v-col>
                  <v-btn
                    block
                    class="boton font-14 px-9 py-4 my-3"
                    @click="processRecogida()"
                    >PROCESAR RECOGIDA</v-btn
                  >
                  <v-btn
                    block
                    class="boton font-14 px-9 py-4 my-3"
                    @click="processRefund()"
                    >DEVOLVER PEDIDO</v-btn
                  >
                </v-col>
                <!-- <v-btn color="blue darken-1" text @click="isOpen = false">Save</v-btn> -->
              </div>

              <div v-else class="mt-4">
                <div v-if="order.status === 'Pagado'">
                  <div class="mb-4">
                    <!-- <v-btn v-if="order.delivered_by!=='local'"  disabled block color="rgb(255, 221, 23)" @click="processLocal('En preparación')">🛴LOCAL🛴</v-btn> -->
                    <v-btn
                      block
                      class="boton font-14 px-9 py-4 my-3"
                      @click="processLocal('En preparación')"
                      >🛴LOCAL🛴</v-btn
                    >
                  </div>
                  <div>
                    <v-btn
                      v-if="order.stuartPrice"
                      block
                      class="boton"
                      @click="processStuart()"
                      >Stuart: {{ order.stuartPrice }}
                    </v-btn>
                  </div>
                  <!-- <div>
                    <v-btn
                      v-if="order.vromoPrice"
                      block
                      class="boton font-14 px-9 py-4 my-3"
                      @click="processVromo()"
                      >Vromo: {{ order.vromoPrice }}
                    </v-btn>
                  </div> -->
                  <div>
                    <v-btn
                      block
                      class="boton font-14 px-9 py-4 my-3"
                      @click="processRefund()"
                      >DEVOLVER PEDIDO</v-btn
                    >
                  </div>
                </div>

                <div
                  v-if="
                    order.status === 'En preparación' ||
                      order.status === 'En espera'
                  "
                >
                  <div class="mb-4">
                    <v-btn block class="boton" @click="cancelStuart()"
                      >Cancelar envío
                    </v-btn>
                  </div>
                </div>
                <!-- FINALIZAR MANUALMENTE  -->
                <div
                  v-if="
                    !isToday(order.createdAt) &&
                      [
                        'En preparación',
                        'En espera',
                        'En reparto',
                        'Finalizado',
                      ].includes(order.status)
                  "
                >
                  <div class="mb-4">
                    <v-btn block class="boton" @click="manualFinish()"
                      >Finalizar manualmente
                    </v-btn>
                  </div>
                </div>

                <div
                  v-if="
                    order.status === 'En preparación' &&
                      order.delivered_by === 'local' &&
                      order.stuartPrice
                  "
                >
                  <div class="mb-4">
                    <v-btn block class="boton" @click="processStuart()"
                      >Stuart: {{ order.stuartPrice }}
                    </v-btn>
                  </div>
                </div>

                <div
                  v-if="
                    order.status === 'En preparación' &&
                      order.delivered_by === 'local'
                  "
                >
                  <div>
                    <v-btn
                      class="boton"
                      block
                      :color="order.status | formatStatusColor"
                      @click="processLocal('En reparto')"
                      >PASAR A "EN REPARTO"</v-btn
                    >
                  </div>
                </div>
                <div
                  v-if="
                    order.status === 'En reparto' &&
                      order.delivered_by === 'local'
                  "
                >
                  <div>
                    <v-btn
                      class="boton"
                      block
                      :color="order.status | formatStatusColor"
                      @click="processLocal('Entregado')"
                      >PASAR A "ENTREGADO"</v-btn
                    >
                  </div>
                </div>
                <!-- <v-btn color="blue darken-1" text @click="isOpen = false">Save</v-btn> -->
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- IFRAME STUART-->

        <v-row
          v-if="order.delivered_by === 'stuart' && order.stuartRes"
          class="mx-2 white-background"
        >
          <hr />
          <v-col class="mx-2">
            <iframe
              class="embed-responsive-item"
              :src="order.stuartRes.trackingUrl"
              width="100%"
              height="450"
              frameborder="0"
              style="border:0"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-row>

        <!-- IFRAME MOX-->

        <!-- <v-row
          v-if="order.delivered_by === 'vromo' && order.vromoRes"
          class="mx-2 white-background"
        >
          <hr />
          <v-col class="mx-2">
            <iframe
              class="embed-responsive-item"
              :src="order.vromoRes.tracking"
              width="100%"
              height="450"
              frameborder="0"
              style="border:0"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-row> -->
      </v-card>

      <v-overlay :value="isFetching">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <v-snackbar
      top
      :timeout="4000"
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn color="black" text @click="snackbar.show = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import {
  PROCESS_LOCAL_ORDER,
  PROCESS_RECOGIDA,
  PROCESS_STUART,
  PROCESS_VROMO,
  CANCEL_STUART,
  REFUND_ORDER,
  MANUAL_FINISH,
} from '@/core/services/store/orders.module';
import { mapState, mapGetters } from 'vuex';
import TicketList from '@/components/TicketList';
import TicketAddress from '@/components/TicketAddress';
import moment from 'moment';
export default {
  components: {
    TicketList,
    TicketAddress,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    order: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: {
        show: false,
        text: '',
        color: '#4CAF50',
      },
      element: {
        size: '',
        variants: [],
        extras: [],
        comments: '',
      },
      overlay: false,
    };
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.orders.isFetching,
    }),
    ...mapGetters(['getZipCodes']),
  },
  methods: {
    isToday(value) {
      let formatType = 'LL';
      if (!value) return '';
      let dateFormated = moment(value);
      let today = moment();
      dateFormated.locale('es');
      today.locale('es');
      return dateFormated.format(formatType) === today.format(formatType);
    },
    clickHandler() {
      // this.isOpen=!this.isOpen
      this.$emit('closeModal', true);
    },
    //TODO hacer una función genérica como dios manda
    processLocal(status) {
      this.$confirm(
        'Está a punto de procesar el pedido con envío local ¿Está seguro?',
        {
          color: '#2196f3',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          let id = this.order._id;
          this.$store
            .dispatch(PROCESS_LOCAL_ORDER, { orderId: id, status })
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            });
        }
      });
    },
    processStuart() {
      this.$confirm(
        'Está a punto de procesar el envío con un repartidor de Stuart ¿Está seguro?',
        {
          color: '#2196f3',
          icon: '',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          this.$store
            .dispatch(PROCESS_STUART, this.order._id)
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            })
            .catch((err) => {
              this.snackbar.color = 'red';
              this.showSnackbar(err);
            });
        }
      });
    },
    processVromo() {
      this.$confirm(
        'Está a punto de procesar el envío con un repartidor de Vromo ¿Está seguro?',
        {
          color: '#2196f3',
          icon: '',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          this.$store
            .dispatch(PROCESS_VROMO, this.order._id)
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            })
            .catch((err) => {
              this.snackbar.color = 'red';
              this.showSnackbar(err);
            });
        }
      });
    },
    manualFinish() {
      this.$confirm(
        'Está a punto de finalizar manualmente un pedido antiguo ¿Está seguro?',
        {
          color: '#2196f3',
          icon: '',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        console.log(this.order);
        if (res) {
          this.$store
            .dispatch(MANUAL_FINISH, {
              jobId: this.order.stuartRes.id,
              orderId: this.order._id,
            })
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
    cancelStuart() {
      this.$confirm(
        'Está a punto de cancelar el envío pendiente con Stuart ¿Está seguro?',
        {
          color: '#2196f3',
          icon: '',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        console.log(this.order);
        if (res) {
          this.$store
            .dispatch(CANCEL_STUART, {
              jobId: this.order.stuartRes.id,
              orderId: this.order._id,
            })
            .then((message) => {
              debugger;
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            })
            .catch((e) => {
              debugger;
              console.log(e);
            });
        }
      });
    },
    processRecogida() {
      this.$confirm(
        'Está a punto de dar por entregado el pedido. ¿Está seguro?',
        {
          color: '#2196f3',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          this.$store
            .dispatch(PROCESS_RECOGIDA, this.order._id)
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            });
        }
      });
    },
    processRefund() {
      this.$confirm('Está a punto de devolver el pedido. ¿Está seguro?', {
        color: '#2196f3',
        title: 'Confirmar acción',
        buttonTrueText: 'Enviar',
        buttonFalseText: 'Cancelar',
      }).then((res) => {
        if (res) {
          this.$store.dispatch(REFUND_ORDER, this.order._id).then((message) => {
            this.$emit('showMessage', message);
            this.$emit('closeModal', true);
          });
        }
      });
    },
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    isLocalAvailable(currentCode) {
      let codigos = this.getZipCodes;
      let codes = codigos.filter((code) => {
        return code.code === currentCode && code.local;
      });
      return codes.length > 0;
    },
  },
  created() {},
};
</script>
<style>
.closeIcon {
  color: #000000;
  position: absolute;
  top: 8px;
  right: 8px;
  /* justify-content: end; */
  /* z-index: inherit; */
  text-align: end;
  /* margin-right: -75px;*/
}

.card__title {
  display: block;
  padding-top: 16px;
  font-size: 16px !important;
  font-weight: 700;
  color: #333333;
}

.card__description {
  display: block;
  padding-top: 4px;
  font-size: 12px !important;
  font-weight: 400;
  color: #808080;
}
.cont {
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
}

.top__chips {
  max-height: 29px;
  font-size: 14px !important;
  font-weight: 300;
}

/* Clases de vuetify para controles radiobutton */
.mdi-radiobox-blank {
  font-size: 16px !important;
}

.v-input--selection-controls__input label {
  font-size: 12px !important;
}

.v-input--selection-controls__input + .v-label {
  font-size: 12px !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 18px !important;
  position: absolute;
  transition: inherit;
  width: 18px !important;
  left: -4px;
  top: calc(50% - 16px) !important;
  margin: 7px;
  bottom: 1px !important;
  left: -4px !important;
  right: -7px !important;
  top: -4px !important;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}

.regular-background {
  background-color: #f3f6f9 !important;
}
</style>
