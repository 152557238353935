<template>
    <div      
      class="mx-auto"
    >
        <div class="">
          <div class="d-flex">
            <div class="d-flex justify-content-start">
              <div class="ficha__amount color__regular">
                x{{ element.amount }}
              </div>
              <div class="ficha__title color__regular ml-2">
                  {{ element.name }}
              </div>
            </div>
            <div>
              <div class="ficha__precio mr-2">
                {{ Math.round(element.totalPrice * 100) / 100  }} €
              </div>  
            </div>                                              
          </div>                                          
          
          <div class="contenido">
            <div v-for="(variant, index) in element.variants" :key="'var_'+index">
              <span class="ficha__section color--secondary">{{ variant.kind }}:</span> <span class="ficha__text color__regular">{{ variant.name }}</span>
            </div>
                                
            
            <div v-if="element.extras.length>0" >
                <span class="ficha__section color--secondary">Extras:</span><span v-for="(extra, index) in element.extras" :key="'extra_'+index" class="ficha__text color__regular"> {{ extra.name }}</span>
            </div>    
            <li v-if="element.comments" :key="'comments_'+index" >
              <span class="ficha__section color--secondary">Comentarios</span> : {{ element.comments }}
            </li>                         
          </div>                  
        </div>                                                              
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props:{
        element:{
            type:Object,
            required:false,
            default:()=>{return[]}
        },
        index:{
          type: Number,
          required:true
        },
        isOnlyInfo:{
          type: Boolean,
          required: false
        },
        itemType:{
          type: String,
          required: false
        }
        
    },       
    data(){
      return{
        amount:1
      }
    },
  methods:{
    removeCartElement(index){      
      this.amount-=1      
      this.$emit('onCartElementRemoved', index)      
    },
    addAmountCartElement(index){        
      this.amount+=1      
      this.$emit('onCartAddedAmount', index)      
    },
    //TODO refactorizar a filter ¿posible?
    //Ver si podemos pasarlo a filter o a una función en otro lugar
    formatExtra(extraId){            
      let extraFound = this.extrasList.find(extra => extra._id === extraId)
      return extraFound.name      
    },
    formatVariantName(productId, variantIndex){      
      let productFound = this.products.find(product => product._id === productId)
      return productFound.variants[variantIndex].name     
    },
    formatVariant(productId, variantIndex, variantValue){      
      let productFound = this.products.find(product => product._id === productId)
      return productFound.variants[variantIndex].vars[variantValue].name      
    },
         

  },
  computed: {
      ...mapState({               
        products: state => state.products.items,                     
        extrasList: state => state.extras.items,                      
      }),      
      totalPrice(){
        let sum=0
        this.cart.forEach(element => {
          sum+=this.calcPrice(element)*this.amount
        });
        
        return Math.round(sum * 100) / 100
      },
      calcPrice(){      
        let sum=0
        let productFound = this.products.find(product => product._id === this.element._id)
        sum+=this.element.price
        if (this.element.size!=''){
          sum+= productFound.sizes.find((size)=>{
                return size._id === this.element.size;
            }).price
        }             
        //Extras

        this.element.extras.forEach(elementExtra => {
          sum+= this.extrasList.find((extra)=>{
                return elementExtra === extra._id;
            }).price
        });

        //Variants        
        if(this.element.variants){
          this.element.variants.forEach((elementVariant, index) => {            
            sum+= this.element.prod_vars[index].vars[elementVariant].price
          });
        }
        

                        
        return Math.round(sum*this.element.amount * 100) / 100
      },
  }
}
</script>
<style lang="scss" scoped>

  .ficha{
      background-color: transparent !important;
      color:black !important;
      padding: 1rem;
      margin-top: .7rem;
      border-radius: 8px;
      
  }

  .ficha__amount{
      text-align: start;
      font-size: 16px;
      font-weight: 700;
  }

  .ficha__title{
      text-align: start;
      font-size: 16px;
      font-weight: 400;      
  }

  .ficha__section{
      font-size: 12px;
      font-weight: 400;
  }
  .ficha__text{
      font-size: 12px;
      font-weight: 400;
  }

  .ficha__precio{
    padding-left: .5rem;
    font-size: 15px;
    font-weight: 500;
    text-align: right;
    align-self: flex-end;
  }

  .contenido{
    padding-left:2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .v-list-item--active::before{
    background-color: transparent !important;
    opacity: 0;
  }
  .v-list-item__title{
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .v-list-item{
    margin-top: -16px;
  }
  .v-expansion-panel-header{
    padding:0;
  }
  
</style>