<template>
    <div      
      class="ticket"

    >
      <!-- <v-system-bar
        color="blue darken-2"
        dark
      >
        <v-spacer></v-spacer>
  
        <v-icon>mdi-window-minimize</v-icon>
  
        <v-icon>mdi-window-maximize</v-icon>
  
        <v-icon>mdi-close</v-icon>
      </v-system-bar> -->
  
      <div>
        
  
        <div class="order">
          <div class="order__title">Pedido 👩🏾‍🍳</div>
          <div class="ml-4"> 
            <span class="font-weight-bolder mb-1 font-size-lg" v-bind:class="{ 'order--pagado': order.status==='Pagado', 'order--preparacion': order.status==='En preparación', 'order--reparto': order.status==='En reparto', 'order--entregado': order.status==='Entregado' }" >
              {{ order.status}}
            </span>
          </div>
        </div>
  
      </div>
  
      <div>
        <v-row dense>          
          <v-col            
            cols="12"
          >
            <v-card              
              class="ficha"          
              v-if="order"    
            >            
              <div v-for="(element, index) in order.items" :key="element.key">
                <TicketListCard :isOnlyInfo=isOnlyInfo :element="element" :index="index" @onorderElementRemoved="removeorderElement"  @onorderAddedAmount="addAmountorderElement" :itemType="'product'"></TicketListCard>                                                         
                <div v-if="index+1!==order.items.length || order.deals>0 " class="text-center mb-4">-----------------------------------------------------</div>
              </div>

              <div v-for="(element, index) in order.deals" :key="element.key">
                <TicketListCardDeal :isOnlyInfo=isOnlyInfo :element="element" :index="index" @onorderElementRemoved="removeorderElement"  @onorderAddedAmount="addAmountorderElement" :itemType="'deal'"></TicketListCardDeal>                                         
                <div v-if="index+1<order.deals.length" class="text-center mb-4">-----------------------------------------------------</div>
              </div>
              
              <hr>
              
              <div class="d-flex justify-space-between mt-6 align-center">
                <div class="order__total--big">Total: </div>
                <div>
                  <span class="order__price--delivery" v-if="order.deliveryPrice">(+ {{ order.deliveryPrice | formatPrice }} € gastos envío)</span>
                  <span class="order__prize">{{ order.orderPrice | formatPrice }} €</span>
                </div>
                
              </div>
            </v-card>
          </v-col>
        </v-row>    
        
      </div>        
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import TicketListCard from '@/components/TicketListCard'
import TicketListCardDeal from '@/components/TicketListCardDeal'
export default {
    components:{
      TicketListCard,
      TicketListCardDeal
    },
    props:{        
        order:{
          type:Object,
          required:false          
        },
        isOnlyInfo:{
          type: Boolean,
          required: false
        }
    },       
    data(){
      return{
        totalVisible:true
      }
    },
  methods:{

    getStatus(status){
        switch(status) {
        case 'Pagado':
            return '💰 PAGADO! 💰'
        case 'En preparación':
            return '💰 PAGADO! 💰'                
        default:
            // code block
        }
    },
    getColor(status){
        switch(status) {
        case 'Pagado':
            return '#4CAF50'
        case 'En preparación':
            // code block
            break;
        default:
            // code block
        }
    },

    removeorderElement(index){      
      this.$emit('onorderElementRemoved', index, 'Elemento eliminado')
      
    },
    addAmountorderElement(index){           
      this.$emit('onorderElemenAmountAdded', index,'Elemento añadido a tu pedido')      
      
    },
    //TODO refactorizar a filter ¿posible?
    //Ver si podemos pasarlo a filter o a una función en otro lugar
    formatExtra(extraId){            
      let extraFound = this.extrasList.find(extra => extra._id === extraId)
      return extraFound.name      
    },
    formatVariant(productId, variantIndex, variantValue){      
      let productFound = this.products.find(product => product._id === productId)
      return productFound.variants[variantIndex].name + ': ' +productFound.variants[variantIndex].vars[variantValue].name      
    },
    calcPrice(element){      
        let sum=0
        let productFound = this.products.find(product => product._id === element._id)
        sum+=element.price
        debugger
        if (element.size!=''){
          sum+= productFound.sizes.find((size)=>{
                return size._id === element.size;
            }).price
        }             
        //Extras

        element.extras.forEach(elementExtra => {
          sum+= this.extrasList.find((extra)=>{
                return elementExtra === extra._id;
            }).price
        });
        
        
        //Variants        
        if(element.variants){
          element.variants.forEach((elementVariant, index) => {            
            sum+= element.prod_vars[index].vars[elementVariant].price
          });
        }      
        
        

        return sum*element.amount
      },     
      toggleTotalVisible(){        
        this.totalVisible=false
        setTimeout(function(){this.totalVisible=true}.bind(this), 100);
        
      }

  },
  computed: {
      ...mapState({               
        products: state => state.products.items,                     
        extrasList: state => state.extras.items,                             
      }),
  }, 
  created(){
  }
}
</script>
<style scoped>

  .ficha{    
      color:black !important;
      padding: 1rem;
      margin-top: .7rem;
      border-radius: 8px;
      
  }

  .ficha__title{
      font-size: 16px;
      font-weight: 500;
  }

  .ficha__precio{
      font-size: 15px;
      font-weight: 500;
  }

  .order{
    /* border-bottom: 1px solid #4496E8!important; */
    display: flex;
    align-items: center;
  }  

  .order__title{
    font-size: 24px;
    font-weight: 700;
  }
  .order__total{
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
  }

  .order__total--big{
    font-size: 20px;
    font-weight: 700;    
  }

  .order__prize{
    font-size: 16px;
    font-weight: 400;
    padding-top: 4.5px;
    margin-left: 6px;
  }

  .order__price--delivery{
    font-size: 12px;
    padding-bottom: 5px;
  }
  
  .v-list-item--active::before{
    background-color: transparent !important;
    opacity: 0;
  }
  .v-list-item__title{
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .v-list-item{
    margin-top: -16px;
  }
  
  .ticket{
    max-width: 400px;
    margin: 0 auto;
  }
  
</style>