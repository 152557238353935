<template>
  <div class="mt-10">
    <h1 class="section__headline">Pedidos</h1>
    <!--begin::Pedidos-->
    <v-container>
      <v-row>
        <!-- <div class="col-xxl-4">
                    <StatsWidget12></StatsWidget12>
                </div>
                <div class="col-xxl-4">
                    <StatsWidget12></StatsWidget12>
                </div>
                <div class="col-xxl-4">
                    <StatsWidget7></StatsWidget7>            
                </div>

                <div class="col-xxl-4 order-1 order-xxl-1">
                    <ListWidget9></ListWidget9>
                </div> -->
        <div class="col-xxl-12 order-2 order-xxl-1">
          <!-- <AdvancedTableWidget2
                        v-if="!isFetching"
                        :orders="orders"></AdvancedTableWidget2> -->
          <PedidosTable :orders="activos" :title="'Activos'" @orderClicked="onOrderClicked" />
        </div>
      </v-row>
      <v-row>
        <div class="col-xxl-7 order-xxl-1 pt-6">
          <!-- <AdvancedTableWidget2
                        v-if="!isFetching"
                        :orders="orders"></AdvancedTableWidget2> -->
          <PedidosTable :finalizados="true" :orders="procesados" :title="'Procesados'" @orderClicked="onOrderClicked" />
        </div>
        <div class="col-xxl-5 order-xxl-1 pt-6">
          <ListWidget9 :logs="logs" />
        </div>
      </v-row>
    </v-container>

    <OrderDialog :isOpen="modalOrder" @closeModal="onCloseModal" :order="modalOrderObject"
      @showMessage="showSnackbar" />
    <IncomingDialog :isOpen="incomingOrder" @closeModal="onIncomingClosed" :order="incomingOrderObject" />
    <TakeAwayReminderDialog :isOpen="recogidasDialog" @closeModal="onReminderClosed"
      :orders="recogidasChecked.length" />
    <RefreshDialog :isOpen="modalRefresh" @closeModal="onCloseRefresh" />
    <!--end::Pedidos-->

    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      <div class="snackbar">
        {{ snackbar.text }}
        <v-btn color="black" text @click="snackbar.show = false"> Close </v-btn>
      </div>
    </v-snackbar>

    <v-dialog v-model="firstModal" persistent max-width="400px" :overlay-opacity="0.9" overlay-color="#333333"
      ref="contenedor">
      <v-card class="px-6 py-6">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-3 text-center">Bienvenido al área de pedidos!!</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div class="d-flex flex-column">
          <v-btn class="boton" @click="closeFirstModal">COMENZAR!!</v-btn>
        </div>

        <v-img alt="Logo" src="media/logos/ob3.png" height="250" class="mt-3" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { GET_ORDERS, SELECT_ORDER } from '@/core/services/store/orders.module';
import { GET_PRODUCTS_BY_OWNER_ID } from '@/core/services/store/products.module';
import { GET_SELLER_LOGS } from '@/core/services/store/logs.module';
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import PedidosTable from '@/view/content/widgets/advance-table/PedidosTable.vue';
import ListWidget9 from '@/view/content/widgets/list/Widget9.vue';
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import OrderDialog from '@/components/OrderDialog';
import IncomingDialog from '@/components/IncomingDialog';
import RefreshDialog from '@/components/RefreshDialog';
import TakeAwayReminderDialog from '@/components/TakeAwayReminderDialog';

export default {
  name: 'Pedidos',
  components: {
    // AdvancedTableWidget2,
    ListWidget9,
    // StatsWidget7,
    // StatsWidget12,
    PedidosTable,
    OrderDialog,
    IncomingDialog,
    TakeAwayReminderDialog,
    RefreshDialog,
  },
  data() {
    return {
      activos: [],
      procesados: [],
      tabIndex: 0,
      clickedOrder: -1,
      firstModal: true,
      modalRefresh: false,
      modalOrder: false,
      modalOrderObject: null,
      incomingOrder: false,
      incomingOrderOpened: false,
      incomingOrderObject: null,
      recogidasCheckerInterval: null,
      recogidasChecked: [],
      recogidasDialog: false,
      pedidosTimer: null,
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  mounted() {
    this.activos = this.getActive();
    this.procesados = this.getProcessed();
    console.log('mounted');
    this.$store
      .dispatch(GET_ORDERS, this.$store.state.auth.user.seller._id)
      .then((res) => { });
    this.$store
      .dispatch(
        GET_PRODUCTS_BY_OWNER_ID,
        this.$store.state.auth.user.seller._id
      )
      .then((res) => { });
    this.recogidasCheckerInterval = setInterval(this.recogidasChecker, 10000);
    // this.recogidasChecker()
  },
  destroyed() {
    clearInterval(this.pedidosTimer);
    clearInterval(this.recogidasCheckerInterval);
  },
  deactivated() {
    console.log('desactivo');
  },
  created() {
    this.$socket.on('seller/orderStored', this.orderStoredHandler);
    this.$socket.on('seller/orderUpdated', this.orderUpdatedHandler);
    this.$socket.on('seller/connected', () => console.log('conectado'));
    this.$socket.on('seller/test', (res) => console.log(`testeado ${res}`));
    console.log('estado del socket');
    console.log(this.$socket.connected);
    this.pedidosTimer = setInterval(() => {
      this.$store.dispatch(GET_ORDERS, this.$store.state.auth.user.seller._id);
    }, 180000); // 3min
    // }, 10000); // 3min
  },
  watch: {
    orders: {
      handler: function (newOrders, oldOrders) {
        if (!this.incomingOrderOpen) {
          if (
            newOrders.filter((order) => order.status !== 'payment_pending').length > 0 &&
            oldOrders.filter((order) => order.status !== 'payment_pending').length < newOrders.filter((order) => order.status !== 'payment_pending').length &&
            oldOrders.length > 0
          ) {
            this.modalRefresh = true;
          }
        } else {
          this.incomingOrderOpen = false;
        }
        this.activos = this.getActive();
        this.procesados = this.getProcessed();
      },
    },
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      selectedOrder: (state) => state.selectedOrder,
      isFetching: (state) => state.orders.isFetching,
      logs: (state) => state.logs.items,
    }),
  },
  methods: {
    getProcessed() {
      let a = this.$store.getters.getProcessed;
      return a;
    },

    getActive() {
      let a = this.$store.getters.getActive;
      return a;
    },
    orderStoredHandler(order) {
      debugger;
      console.log('recibida orden')
      this.incomingOrderObject = order;
      this.incomingOrder = true;
      // this.$store.dispatch('')
    },
    orderUpdatedHandler(order) {
      console.log('pedido actualizado');
      this.showSnackbar('Un pedido se ha actualizado!');
      this.$store.dispatch(GET_ORDERS, this.$store.state.auth.user.seller._id);
      // this.$store.dispatch('')
    },
    onOrderClicked(item) {
      //Si llega aquí, es que está recibiendo si o si un valor válido
      this.modalOrderObject = item;
      this.modalOrder = true;
      // this.$store.dispatch(SELECT_ORDER, id)
    },
    onIncomingClosed() {
      this.$store.dispatch(GET_ORDERS, this.$store.state.auth.user.seller._id);
      this.incomingOrder = false;
      this.incomingOrderOpen = true;
      this.incomingOrderObject = null;
    },
    onReminderClosed() {
      this.recogidasDialog = false;
    },
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    closeFirstModal() {
      this.firstModal = false;
    },
    onCloseModal() {
      this.modalOrder = false;
      this.modalOrderObject = null;
    },
    onCloseRefresh() {
      this.modalRefresh = false;
    },
    recogidasChecker() {
      let now = new Date();
      let recogida1 = this.getActive[0]
        ? new Date(this.getActive[0].takeawayTime)
        : null;
      let takeaway = [];
      //Controlamos que no haya aparecido mas veces el mensaje por ese pedido
      this.activos.forEach((item) => {
        if (item.delivery === 'takeaway') {
          let recogida = new Date(item.takeawayTime);
          let flag = this.recogidasChecked.map((item2) => {
            if (item._id === item2._id) return item2;
          });
          if (
            (recogida.getTime() - now.getTime()) / 60000 < 20 &&
            (recogida.getTime() - now.getTime()) / 60000 > 0 &&
            flag.length === 0
          ) {
            takeaway.push(item);
          }
        }
      });
      //Refrescamos el pedido
      if (takeaway.length > 0) this.recogidasChecked = takeaway;
      if (takeaway.length > 0) this.recogidasDialog = true;
    },
  },
};
</script>
<style>
.timeline-item {
  min-height: 28px !important;
}

.headline {
  font-size: 18px;
  font-weight: 600;
}

.snackbar {
  font-weight: 600;
  font-size: 12px;
}
</style>
