<template>
    <div data-app v-if="orders>0">    
        
        <v-dialog v-model="isOpen" persistent max-width="400px" :overlay-opacity=0.9 overlay-color="#333333" ref="contenedor">                    

            <v-card class="px-6 py-6">                        

                <div class="closeIcon">
                    <v-btn
                        icon                
                        @click="clickHandler"               
                        color="#000000"                    
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>                       

                <v-list-item three-line>
                    <v-list-item-content>                
                        <v-list-item-title class="headline mb-3">¡RECORDATORIO!</v-list-item-title>
                        <v-list-item-subtitle><strong>Hay {{orders}}  pedidos para recoger que vendrán en menos de 20 min </strong></v-list-item-subtitle>
                    </v-list-item-content>     

                    <v-list-item-avatar
                        tile
                        size="80"
                        color="grey"
                        >
                        <img
                        src="https://img.icons8.com/cotton/2x/take-away-food.png"
                        alt="Take away"
                        >
                    </v-list-item-avatar>
                </v-list-item>              
                <v-card-actions>
                    
                        <v-col>
                            <v-btn block color="rgb(255, 221, 23)" @click="clickHandler" >¡OIDO!</v-btn>                    
                        </v-col>
                    
                </v-card-actions>      
            </v-card>
        </v-dialog>     

    </div>
</template>
<script>

export default {
    components:{
        
    },
    props:{
        isOpen:{
            type: Boolean,
            default: false,
            required: true
        },
        orders:{
            type: Number,
            default:null,
            required:false
        },        
    },
    data(){
        return{
            dialog:false,
            snackbar:{
                show:false,
                text:''
            },
            element:{                            
                size:'',
                variants:[],
                extras:[],
                comments:''              
            },            
            audio : new Audio('https://papamono.es/campana.mp3') // path to file
        }
    },
    watch: { 
      	isOpen: function(newVal, oldVal) { // watch it
            if (newVal) {
                if (newVal===true) this.playSound()
            }
        }
    },    
    methods:{
        clickHandler(){
            this.audio.pause()
            this.$emit('closeModal', true)
        },
        //TODO hacer una función genérica como dios manda
        playSound(){
            this.audio.loop=true
            this.audio.play();    
        }
       
    },            
}
</script>
<style>

    .closeIcon{
        color: #000000;
        position: absolute;
        top: 8px;
        right: 8px;
        text-align: end;
    }    


    .headline{        
        font-size: 18px;
        font-weight: 600;        
    }

</style>
