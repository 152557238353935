<template>
    <div      
      class="mx-auto"
    >
        <div class="">
          <v-expansion-panels
              flat              
            >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="ficha__amount color__regular">
                    {{ /* Las ofertas siempre se compran individualmente asi que ponemos un 1 */}}
                    x1 
                </span>      
                <span class="ficha__title color__regular">
                    {{ element.dealType.name }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for=" (item, index) in element.items " :key="'dealItem_'+index">
                  <div class="contenido">
                    <span class="ficha__amount color__regular ficha__amount--subItem">                        
                        x{{ item.amount }}
                    </span>      
                    <span class="ficha__title color__regular ficha__subItem">
                        {{ item.name }}
                    </span>
                    <div v-for="(variant, index1) in item.variants" :key="'var_'+index1">
                      <span class="ficha__section color--secondary ml-2">{{ variant.kind }}:</span> <span class="ficha__text color__regular">{{ variant.name }}</span>
                    </div>
                                        
                    
                    <div v-if="item.extras.length>0" >
                        <span class="ficha__section color--secondary ml-2">Extras:</span><span v-for="(extra, index) in item.extras" :key="'extra_'+index" class="ficha__text color__regular"> {{ extra.name }}</span>
                    </div>    
                    <li v-if="item.comments" :key="'comments_'+index" >
                      <span class="ficha__section color--secondary ml-2">Comentarios</span> : {{ item.comments }}
                    </li>                         
                  </div>   
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>           
        <div class="ficha__precio d-flex flex-no-wrap justify-space-between mb-2">
            <div>{{ Math.round(element.dealType.price * 100) / 100  }} €</div>            
        </div>                                                  
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props:{
        element:{
            type:Object,
            required:false,
            default:()=>{return[]}
        },
        index:{
          type: Number,
          required:true
        },
        isOnlyInfo:{
          type: Boolean,
          required: false
        },
        itemType:{
          type: String,
          required: false
        }
        
    },       
    data(){
      return{
        amount:1
      }
    },
  methods:{
    removeCartElement(index){      
      this.amount-=1      
      this.$emit('onCartElementRemoved', index)      
    },
    addAmountCartElement(index){        
      this.amount+=1      
      this.$emit('onCartAddedAmount', index)      
    },
    //TODO refactorizar a filter ¿posible?
    //Ver si podemos pasarlo a filter o a una función en otro lugar
    formatExtra(extraId){            
      let extraFound = this.extrasList.find(extra => extra._id === extraId)
      return extraFound.name      
    },
    formatVariantName(productId, variantIndex){      
      let productFound = this.products.find(product => product._id === productId)
      return productFound.variants[variantIndex].name     
    },
    formatVariant(productId, variantIndex, variantValue){      
      let productFound = this.products.find(product => product._id === productId)
      return productFound.variants[variantIndex].vars[variantValue].name      
    },
         

  },
  computed: {
      ...mapState({               
        products: state => state.products.items,                     
        extrasList: state => state.extras.items,                      
      }),      
      totalPrice(){
        let sum=0
        this.cart.forEach(element => {
          sum+=this.calcPrice(element)*this.amount
        });
        
        return Math.round(sum * 100) / 100
      },
      calcPrice(){      
        let sum=0
        let productFound = this.products.find(product => product._id === this.element._id)
        sum+=this.element.price
        if (this.element.size!=''){
          sum+= productFound.sizes.find((size)=>{
                return size._id === this.element.size;
            }).price
        }             
        //Extras

        this.element.extras.forEach(elementExtra => {
          sum+= this.extrasList.find((extra)=>{
                return elementExtra === extra._id;
            }).price
        });

        //Variants        
        if(this.element.variants){
          this.element.variants.forEach((elementVariant, index) => {            
            sum+= this.element.prod_vars[index].vars[elementVariant].price
          });
        }
        

                        
        return Math.round(sum*this.element.amount * 100) / 100
      },
  }
}
</script>
<style lang="scss" scoped>

  .ficha{
      background-color: transparent !important;
      color:black !important;
      padding: 1rem;
      margin-top: .7rem;
      border-radius: 8px;
      
  }

  .ficha__amount{
      text-align: start;
      font-size: 16px;
      font-weight: 700;
      margin-right: 5px;
      max-width: min-content;
  }

  .ficha__title{
      text-align: start;
      font-size: 16px;
      font-weight: 400;      
  }

  .ficha__subItem{
      font-size: 14px;
      font-weight: 400;
  }

  .ficha__amount--subItem{
      text-align: start;
      font-size: 14px;
      font-weight: 700;
      margin-right: 5px;
      max-width: min-content;
  }

  .ficha__section{
      font-size: 12px;
      font-weight: 400;
  }
  .ficha__text{
      font-size: 12px;
      font-weight: 400;
  }

  .ficha__precio{
    padding-left: .5rem;
    font-size: 15px;
    font-weight: 500;
  }

  .contenido{
    padding-left:.2rem;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }

  .v-list-item--active::before{
    background-color: transparent !important;
    opacity: 0;
  }
  .v-list-item__title{
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .v-list-item{
    margin-top: -16px;
  }
  .v-expansion-panel-header{
    padding:0;
  }
  
</style>