<template>
  <div class="ticket">
    <!-- <v-system-bar
        color="blue darken-2"
        dark
      >
        <v-spacer></v-spacer>
  
        <v-icon>mdi-window-minimize</v-icon>
  
        <v-icon>mdi-window-maximize</v-icon>
  
        <v-icon>mdi-close</v-icon>
      </v-system-bar> -->

    <div>
      <div class="order">
        <span class="order__title">Datos entrega 🧑🏽‍🏫</span>
      </div>
    </div>

    <div>
      <v-row dense>
        <v-col>
          <v-card class="ficha" v-if="customerData">
            <div>
              <p>
                <span class="ficha__amount color__regular">Contacto: </span>
                <span class="ficha__title color__regular">
                  {{ customerData.firstName }} {{ customerData.lastName }}</span
                >
              </p>

              <p>
                <span class="ficha__amount color__regular">Teléfono: </span>
                <span class="ficha__title color__regular">
                  {{ customerData.phone }}</span
                >
              </p>

              <div v-if="delivery === 'delivery'">
                <p>
                  <span class="ficha__amount color__regular">Dirección: </span>
                  <span class="ficha__title color__regular">
                    {{ customerData.address }} {{ customerData.address2 }},
                    {{ customerData.zipCode }} {{ customerData.city }}
                  </span>
                </p>
                <p v-if="stuartData && stuartData.etaToDestination">
                  <span class="ficha__amount color__regular"
                    >Hora entrega aproximada:
                  </span>
                  <span class="ficha__title color__regular">
                    {{ stuartData.etaToDestination | formatTime }}
                  </span>
                </p>
                <p v-if="order.delivered_by === 'stuart'">
                  <span class="ficha__amount color__regular"
                    >Coste de reparto por Stuart:
                  </span>
                  <span class="ficha__title color__regular">
                    {{ order.deliveryCost | formatPrice }} €</span
                  >
                </p>
              </div>
              <div v-else>
                <p>
                  <span class="ficha__amount color__regular">Recogida: </span>
                  <span class="ficha__title color__regular">
                    {{ takeawayTime | formatTime }}</span
                  >
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  props: {
    customerData: {
      type: Object,
      required: false,
    },
    takeawayTime: {
      type: String,
      required: false,
    },
    delivery: {
      type: String,
      required: false,
    },
    stuartData: {
      type: Object,
      required: false,
    },
    order: {
      type: Object,
      required: false,
    },
    stuartPrice: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      totalVisible: true,
    };
  },
  methods: {
    timeDifference() {
      debugger;
      var now = moment(moment().utc());
      var then = moment(this.takeawayTime);
      let diff = moment(then.diff(now)).format('HH:mm');
      return diff;
    },
  },
};
</script>
<style scoped>
.ficha {
  background-color: #ffffff !important;
  color: black !important;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 8px;
}

.ficha__title {
  font-size: 16px;
  font-weight: 500;
}

.ficha__precio {
  font-size: 15px;
  font-weight: 500;
}

.order {
  /* border-bottom: 1px solid #4496E8!important; */
}

.order__title {
  font-size: 24px;
  font-weight: 700;
}
.order__total {
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
}

.order__total--big {
  font-size: 20px;
  font-weight: 700;
}

.order__prize {
  font-size: 16px;
  font-weight: 400;
  padding-top: 4.5px;
  margin-left: 6px;
}

.v-list-item--active::before {
  background-color: transparent !important;
  opacity: 0;
}
.v-list-item__title {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.v-list-item {
  margin-top: -16px;
}

.ticket {
  max-width: 400px;
  margin: 0 auto;
}

.ficha__amount {
  text-align: start;
  font-size: 16px;
  font-weight: 700;
}

.ficha__title {
  text-align: start;
  font-size: 16px;
  font-weight: 400;
}

.ficha__section {
  font-size: 12px;
  font-weight: 400;
}
.ficha__text {
  font-size: 12px;
}
</style>
